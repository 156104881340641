import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

export const About = () => {
  return (
    <div className="about-div">
      <h1 className="about-head text-center flex items-center justify-center gap-5 pb-5">
        <span>
          {/* <img className='h-10' src="https://cdn-icons-png.flaticon.com/128/189/189664.png" alt="My About" /> */}
          <Icon
            icon="mdi:about"
            width="40"
            height="40"
            style={{ color: "#f674ff" }}
          />
        </span>
        <span className="text-4xl font-bold from-[#f674ff] to-[#ff6b19] bg-gradient-to-r bg-clip-text text-transparent">
          About
        </span>
      </h1>

      <div className="about-content w-full flex flex-col md:flex-row items-center justify-center">
        <div className="w-[50%] md:w-[30%] lg:w-[20%] p-3 md:flex md:justify-end">
          <img
            className="w-[100%]"
            src="https://pics.craiyon.com/2023-10-26/5e86e55df03c4bbdbbdaa6a261c4a0d6.webp"
            alt="about_photo"
          />
        </div>
        <div className="w-[80%] md:w-[50%] lg:w-[60%] p-3">
          <p className="p-2 w-full text-xl text-justify justify-center font-semibold">
            &emsp;Dedicated and enthusiastic Full Stack Developer with 10 months
            of experience and a strong foundation in the MERN stack. Proficient
            in HTML, CSS, JavaScript, Java, React, MongoDB, Node.js, and
            Express.js. Adept at fostering impactful communication, contributing
            effectively to team dynamics, and adapting to new frameworks. Eager
            to apply these attributes in a collaborative and growth-focused
            environment to drive success and contribute positively to the
            organization{"’"}s goals.
          </p>
        </div>
      </div>
    </div>
  );
};
