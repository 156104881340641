import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import dana_logo from "../assets/images/dana_logo.png";

export const Work = () => {
  const experience = [
    {
        company: {
          name: "Versalence Infocomm Pnt Ltd",
          logo: "https://versal.one/assets/images/favicon.png",
          employmentType: "Full-time",
        },
        position: "Software Developer",
        duration: {
          startYear: 2024,
          end: "Present",
          total_exp: "10 mos",
        },
        location: "Bangalore, Karnataka, India",
        description: [
          "Developed scalable backend applications using Node.js and Express.js, handling RESTful APIs, authentication, and authorization using JWT, MongoDB.",
          "Deployed backend APIs using Express.js, resulting in better interoperability among nodes and a 25% increase in data processing speed. Achieving 85% code coverage, and reduced bug counts by 30%.",
          "Collaborated with frontend developers to integrate APIs with React.js applications.",
          "Skills – React.js, Node.js, Express.js, MongoDB, Graph API (Meta), Payment Gateway, Soft skills.",
        ],
        technologies: [
          "React.js",
          "Node.js",
          "Express.js",
          "MongoDB",
          "My SQL",
          "Graph API (Meta)",
          "Payment Gateway",
          "AI Bot",
          "CSS",
          "Tailwind CSS",
          "Soft skills",
        ],
        website: "https://versalence.ai",
      },
    {
      company: {
        name: "Dana",
        logo: dana_logo,
        employmentType: "Part-time",
      },
      position: "Member, Full Stack Developer",
      duration: {
        startYear: 2023,
        end: "Present",
        total_exp: "1 Year 2 mos",
      },
      location: "Kalyani, West Bengal, India",
      description: [
        "This website is created by me and I am still its developer.",
      ],
      technologies: [
        "React.js",
        "Node.js",
        "Express.js",
        "MongoDB",
        "CSS",
        "Tailwind CSS",
      ],
      website: "http://dana.org.in",
    },
  ];
  return (
    <div className="work-div">
      <h1 className="flex justify-center items-center gap-5">
        <Icon
          icon="raphael:employee"
          width="40"
          height="40"
          style={{ color: "#f674ff" }}
        />
        <span className="text-4xl font-bold from-[#f674ff] to-[#ff6b19] bg-gradient-to-r bg-clip-text text-transparent">
          Work
        </span>
      </h1>

      {/* body of work*/}
      <div className="w-full pt-10 p-3 flex flex-col items-center">
        <div className="w-[95%] md:w-[90%] border-b-[0.7px] border-[#626262]"></div>

        {/* 1st Experence */}
        {experience.map((exp, index) => (
          <div
            key={index}
            className="w-[95%] md:w-[90%] py-5 flex gap-3 md:gap-10   border-b-[0.7px] border-[#626262]"
          >
            <div className="w-[25%] md:w-[20%] lg:w-[10%]">
              <img
                className="w-full bg-white"
                src={exp.company.logo}
                alt="Dana"
              />
            </div>
            <div className="w-full">
              <h1 className="text-xl font-bold text-white">{exp.position}</h1>
              <p className="text-[#b6b6b6]">
                <span>
                  {exp.company.name} . {exp.company.employmentType}
                </span>{" "}
                <br />
                <span>
                  {exp.duration.startYear} - {exp.duration.end} .{" "}
                  {exp.duration.total_exp}
                </span>{" "}
                <br />
                <span>{exp.location}</span> <br />
                <ul className="">
                  {exp.description.map((des, index_desc) => (
                    <li key={index_desc} className="pl-5">
                      * {des}
                    </li>
                  ))}
                </ul>
                <span>
                  {" "}
                  Working with{" "}
                  {exp.technologies.map((technologie, index_tech) => (
                    <React.Fragment key={index_tech}>
                      <u className="font-bold">{technologie}</u>,{" "}
                    </React.Fragment>
                  ))}
                </span>{" "}
                <br />
                <a className="text-blue-500 underline" href={exp.website}>
                  <span>{exp.website}</span>
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
